
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import debounce from 'lodash-es/debounce';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { PaginatedResponse, Patient, Thread, User } from '@/models';
import { ThreadService } from '@/services/api';
import VirtualDoctorLayout from '@/custom/menicon/layouts/VirtualDoctorLayout.vue';
import {
  BaseButton,
  ThreadModal,
  DataTable,
  BaseIcon,
  BasePopover,
  BasePagination,
  BaseModal,
  SearchInput,
  BaseTextInput,
  BaseTextarea,
  BaseSwitch
} from '@/lib/components';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(relativeTime);
@Options({
  props: {
    patient: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseSwitch,
    BaseTextarea,
    BaseTextInput,
    SearchInput,
    BaseModal,
    VirtualDoctorLayout,
    BasePagination,
    BaseIcon,
    ThreadModal,
    BaseButton,
    DataTable,
    BasePopover
  }
})
export default class VirtualDoctorMessages extends Vue {
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  loading = true;
  showThreadModal = false;
  request: CancelTokenSource | null = null;
  patient!: Patient;

  threads: Array<Thread> = [];
  thread: Thread | null = null;
  perPage = 0;
  total = 0;
  search = '';

  messagesPage = 0;
  messagesTotal = 0;
  messagesPerPage = 10;
  messagesLoading = false;

  threadService = new ThreadService();
  showNewMessageModal = false;
  title = '';
  message = '';

  canReply = true;

  errors: { [key: string]: string[] } = {};

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  get columns() {
    return [
      // { name: 'id', label: 'ID', sortable: true },
      { name: 'subject', label: this.$t('custom.menicon.virtual-doctor.subject') },
      { name: 'status', label: this.$t('platform.common.status') },
      { name: 'last_activity', label: this.$t('platform.common.last-activity') },
      { name: 'actions', label: '', align: 'right' }
    ];
  }

  get currentUser(): User {
    return this.sessionStore.currentUser;
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'title';
  }

  get rows() {
    return this.threads.map((thread) => ({
      ...thread,
      status: this.getStatus(thread)
    }));
  }

  created() {
    this.progressStore.startProgress();
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  async mounted() {
    this.search = String(this.$route.query.search || '');
    await this.fetchThreads();
    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchThreads();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  closeNewMessageModal() {
    this.title = '';
    this.message = '';
    this.canReply = false;
    this.showNewMessageModal = false;
  }

  async createThread() {
    try {
      this.errors = {};
      const thread = {
        can_reply: this.canReply,
        title: this.title,
        message: this.message,
        patient_id: this.patient.id,
        org_unit_id: this.sessionStore.currentOrganisationId
      };
      const response = await this.threadService.create(thread);
      const newThread: Thread = {
        ...response.data,
        last_message: {
          message: thread.message,
          created_time: new Date().toISOString(),
          sender: {
            id: this.currentUser.id,
            name: `${this.currentUser.given_name} ${this.currentUser.family_name}`,
            type: 'user'
          }
        }
      };
      this.threads = [newThread, ...this.threads];
      this.closeNewMessageModal();
      this.notificationStore.snackbar = {
        label: this.$t('custom.menicon.virtual-doctor.message-sent')
      };
    } catch (e) {
      this.errors = e.response.data.errors;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('custom.menicon.virtual-doctor.create-thread-error')
      });
    }
  }

  update(patient: Patient) {
    this.patient = patient;
  }

  async fetchThreads() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        page: this.page,
        sort: this.sort,
        org_unit_id: this.organisationId,
        'filter[patient_id]': this.patient.id,
        ...(this.search ? { 'filter[search]': this.search } : {})
      },
      cancelToken: this.request.token
    };
    try {
      const response = (await this.threadService.index(requestConfig)) as PaginatedResponse<Thread[]>;
      this.threads = response.data;
      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.menicon.virtual-doctor.fetch-messages-error')
        });
      }
    } finally {
      this.loading = false;
      this.request = null;
    }
  }

  async fetchThread(id: string) {
    if (
      !this.messagesLoading &&
      (!this.messagesTotal || this.messagesTotal > this.messagesPage * this.messagesPerPage)
    ) {
      this.messagesLoading = true;
      this.messagesPage += 1;
      const response = await this.threadService.fetch(id, {
        params: {
          org_unit_id: this.organisationId,
          page: this.messagesPage
        }
      });
      this.thread = {
        ...response.data,
        messages: this.thread ? [...response.data.messages, ...this.thread.messages] : response.data.messages,
        unread: false
      };
      const index = this.threads.findIndex((t) => t.id === id);
      this.threads.splice(index, 1, {
        ...this.threads[index],
        unread: false
      });
      this.messagesTotal = +response.meta.total;
      this.messagesPerPage = response.meta.per_page;
      this.messagesLoading = false;
    }
  }

  getStatus(thread: Thread) {
    if (thread.closed_time) {
      return {
        name: this.$t('custom.menicon.virtual-doctor.closed'),
        color: 'gray-500',
        textColor: 'gray-500'
      };
    } else if (thread.unread) {
      return {
        name: this.$t('custom.menicon.virtual-doctor.awaiting-reply'),
        color: 'alert-500',
        textColor: 'gray-900'
      };
    }
    return {
      name: this.$t('custom.menicon.virtual-doctor.open'),
      color: 'primary-500',
      textColor: 'gray-900'
    };
  }

  async openThread(id: string) {
    this.showThreadModal = true;
    try {
      await this.fetchThread(id);
    } catch (e) {
      this.showNewMessageModal = false;
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.error'),
        label: this.$t('custom.menicon.virtual-doctor.fetch-thread-error')
      });
    }
  }

  timeFromNow(date: string) {
    return dayjs(date).fromNow();
  }

  async closeThread() {
    if (this.thread) {
      const response = await this.threadService.update(this.thread.id, {
        org_unit_id: this.organisationId,
        closed: true
      });
      const index = this.threads.findIndex((t) => t.id === response.id);
      const thread = {
        ...this.thread,
        closed_time: response.closed_time,
        last_message: response.messages[0]
      };
      this.threads.splice(index, 1, thread);
      this.thread = thread;
    }
  }

  closeModal() {
    this.thread = null;
    this.showThreadModal = false;
    this.messagesPage = 0;
    this.messagesTotal = 0;
    this.messagesPerPage = 10;
  }

  async sendMessage(message: string) {
    if (this.thread) {
      const data = {
        thread_id: this.thread.id,
        org_unit_id: this.sessionStore.currentOrganisationId,
        message
      };
      const response = await this.threadService.createMessage(data);
      this.thread.messages.push(response);
    }
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }
}
