import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "text-base font-medium text-gray-900" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "flex flex-row items-center text-sm font-medium space-x-3" }
const _hoisted_4 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_5 = { class: "text-sm font-medium text-gray-500" }
const _hoisted_6 = { class: "space-x-4" }
const _hoisted_7 = { class: "p-10 space-y-8" }
const _hoisted_8 = { class: "flex flex-row justify-between" }
const _hoisted_9 = { class: "space-y-4 flex flex-col" }
const _hoisted_10 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_11 = { class: "flex flex-row justify-between" }
const _hoisted_12 = { class: "space-x-2" }
const _hoisted_13 = { class: "flex flex-row items-center mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MkBadge = _resolveComponent("MkBadge")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_ThreadModal = _resolveComponent("ThreadModal")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!
  const _component_BaseSwitch = _resolveComponent("BaseSwitch")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_VirtualDoctorLayout = _resolveComponent("VirtualDoctorLayout")!

  return (_openBlock(), _createBlock(_component_VirtualDoctorLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_DataTable, {
          loading: _ctx.loading,
          sort: _ctx.sort,
          class: "w-full mt-6",
          columns: _ctx.columns,
          rows: _ctx.rows,
          onSort: _ctx.updateSort
        }, {
          "cell.id": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(row.id), 1)
          ]),
          "cell.subject": _withCtx(({ row }) => [
            _createElementVNode("span", {
              title: row.title,
              class: "text-base font-medium text-gray-900 truncate"
            }, _toDisplayString(row.title), 9, _hoisted_2)
          ]),
          "cell.status": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["rounded-full h-2 w-2", 'bg-' + row.status.color])
              }, null, 2),
              _createElementVNode("span", {
                class: _normalizeClass('text-' + row.status.textColor)
              }, _toDisplayString(row.status.name), 3)
            ])
          ]),
          "cell.last_activity": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(row.closed_time
                  ? _ctx.$t('custom.menicon.virtual-doctor.thread-closed')
                  : _ctx.$t('custom.menicon.virtual-doctor.replied', [
                    row.last_message.sender.id === _ctx.currentUser.id
                      ? _ctx.$t('custom.menicon.virtual-doctor.you')
                      : row.last_message.sender.name
                  ])), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.timeFromNow(row.closed_time ? row.closed_time : row.last_message.created_time)), 1)
          ]),
          "cell.actions": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_6, [
              (row.unread)
                ? (_openBlock(), _createBlock(_component_MkBadge, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.unread')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_BaseButton, {
                size: "small",
                color: "ghost",
                "right-icon": "chevron-right",
                onClick: ($event: any) => (_ctx.openThread(row.id))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.view')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ]),
          _: 1
        }, 8, ["loading", "sort", "columns", "rows", "onSort"]),
        (_ctx.total && _ctx.total > _ctx.perPage)
          ? (_openBlock(), _createBlock(_component_BasePagination, {
              key: 0,
              "sm-pages-limit": 8,
              "model-value": _ctx.page,
              "items-name": _ctx.$t('custom.menicon.virtual-doctor.messages').toLowerCase(),
              "page-size": _ctx.perPage,
              length: _ctx.total,
              "onUpdate:modelValue": _ctx.changePage
            }, null, 8, ["model-value", "items-name", "page-size", "length", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showThreadModal)
        ? (_openBlock(), _createBlock(_component_ThreadModal, {
            key: 0,
            patient: _ctx.patient,
            title: _ctx.thread ? _ctx.thread.title : '',
            messages: _ctx.thread ? _ctx.thread.messages : [],
            readonly: _ctx.thread ? _ctx.thread.closed_time !== null : true,
            loading: !_ctx.thread,
            onClose: _ctx.closeModal,
            onCloseThread: _ctx.closeThread,
            onSend: _ctx.sendMessage,
            onScrolled: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fetchThread(_ctx.thread.id)))
          }, null, 8, ["patient", "title", "messages", "readonly", "loading", "onClose", "onCloseThread", "onSend"]))
        : _createCommentVNode("", true),
      (_ctx.showNewMessageModal)
        ? (_openBlock(), _createBlock(_component_BaseModal, {
            key: 1,
            "dialog-class": "w-148",
            onClose: _ctx.closeNewMessageModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.new-message-to', [`${_ctx.patient.first_name} ${_ctx.patient.last_name}`])), 1)
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "flex items-center justify-center w-6 h-6 bg-gray-300 hover:bg-gray-500 focus:bg-gray-500 focus:outline-none text-white rounded-full",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeNewMessageModal && _ctx.closeNewMessageModal(...args)))
                  }, [
                    _createVNode(_component_BaseIcon, {
                      class: "stroke-1.5 text-xl p-1",
                      name: "close"
                    })
                  ])
                ]),
                _createVNode(_component_BaseTextInput, {
                  modelValue: _ctx.title,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.title) = $event)),
                  label: _ctx.$t('platform.common.title'),
                  placeholder: _ctx.$t('custom.menicon.virtual-doctor.enter-message-title'),
                  error: _ctx.errors.title ? _ctx.errors.title[0] : null
                }, null, 8, ["modelValue", "label", "placeholder", "error"]),
                _createVNode(_component_BaseTextarea, {
                  modelValue: _ctx.message,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.message) = $event)),
                  rows: 2,
                  label: _ctx.$t('platform.common.message'),
                  placeholder: _ctx.$t('custom.menicon.virtual-doctor.enter-message'),
                  error: _ctx.errors.message ? _ctx.errors.message[0] : null
                }, null, 8, ["modelValue", "label", "placeholder", "error"]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_BaseSwitch, {
                    modelValue: _ctx.canReply,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.canReply) = $event)),
                    label: _ctx.$t('custom.menicon.virtual-doctor.allow-reply'),
                    "label-position": "right"
                  }, null, 8, ["modelValue", "label"]),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_BaseButton, {
                      size: "small",
                      color: "ghost",
                      onClick: _ctx.closeNewMessageModal
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_BaseButton, {
                      disabled: !_ctx.title.length || !_ctx.message.length,
                      size: "small",
                      onClick: _ctx.createThread
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('platform.common.send')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["onClose"]))
        : _createCommentVNode("", true)
    ]),
    "right-div": _withCtx(() => [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_SearchInput, {
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event)),
          icon: "search",
          size: "small",
          class: "mr-4 w-48 text-sm",
          onChange: _ctx.updateSearchDebounced
        }, null, 8, ["modelValue", "onChange"]),
        _createVNode(_component_BaseButton, {
          "left-icon": "add",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showNewMessageModal = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('custom.menicon.virtual-doctor.new-message')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}